import { useAuth0 } from '@auth0/auth0-vue';

export class SessionService {
  isAuthenticated;
  isLoading;
  user;
  getAccessTokenSilently;

  constructor() {
    const { isAuthenticated, user, isLoading, getAccessTokenSilently } = useAuth0();
    this.isAuthenticated = isAuthenticated;
    this.user = user;
    this.isLoading = isLoading;
    this.getAccessTokenSilently = getAccessTokenSilently;
  }

  async asyncGetCurrentToken() {
    return await this.getAccessTokenSilently();
  }

  authIsLoading() {
    return this.isLoading;
  }

  getUser() {
    return this.user.value;
  }

  getUserId() {
    const user = this.getUser();
    if (user) {
      return user.sub.replace('|', '-');
    }
    return null;
  }

  getEmailVerified() {
    return this.getUser().email_verified;
  }

  logAllUserProperties() {
    const code = this.getUser() ? JSON.stringify(this.getUser(), null, 2) : '';
    console.log(code);
  }

  isUserAuthenticated() {
    return this.isAuthenticated.value;
  }

  isUserAdmin() {
    return this.getUser().is_admin;
  }
}
