/**
 * plugins/vuetify.js
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css';
import { VBtn } from 'vuetify/components/VBtn';
import 'vuetify/styles';

// Composables
import { createVuetify } from 'vuetify';

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  theme: {
    defaultTheme: 'light'
  },
  aliases: {
    VBtnRounded: VBtn
  },
  defaults: {
    VBtn: {
      style: 'text-transform: none;'
    },
    VBtnRounded: {
      size: 'large',
      elevation: 5,
      color: 'green',
      class: 'rounded-xl',
      style: 'text-transform: none;'
    }
  }
});
