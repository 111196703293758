export class ExcelDownloadAdaptor {
  fetchService;

  constructor(fetchService) {
    this.fetchService = fetchService;
  }

  async asyncGetResultExcel() {
    return this.fetchService.fetchWithAuth(
      '/admin/excel/download/results',
      { method: 'GET' },
      true,
      'blob'
    );
  }
}
