<template>
  <div id="footer">
    <div class="footer-section">
      <p>info@wij2030.nl</p>
    </div>

    <div>
      <a href="/privacy" id="privacy-statement">
        <p>Privacyverklaring</p>
      </a>
    </div>

    <div id="cooperation">
      <div><p>WIJ2030 is een trotse samenwerking van</p></div>
      <div>
        <a href="https://www.hva.nl/" target="”_blank”">
          <img
            src="../../assets/hva-logo-compact.png"
            class="social-logo"
            alt="HvA"
            style="margin-right: 50px"
          />
        </a>
        <a href="https://f-fort.nl/" target="”_blank”">
          <img src="../../assets/ffort-logo.png" class="social-logo" alt="F-Fort" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer'
};
</script>

<style scoped>
#footer {
  font-weight: bold;
  font-size: 18px;
  color: #565554;
  height: 7rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-top: 3px solid #565554;
  margin-left: 40px;
  margin-right: 40px;
}

#privacy-statement {
  text-decoration: none;
  color: inherit;
}

#cooperation {
  margin-top: 20px;
}

.social-logo {
  max-width: 150px;
  margin-bottom: 20px;
  vertical-align: middle;
}

@media (max-width: 1000px) {
  #footer {
    height: 10rem;
    width: 80vw;
    margin-left: 9%;
  }

  #privacy-statement {
    margin: 0;
    visibility: hidden;
    width: 0;
  }

  #contact-information {
    padding-bottom: 50px;
    margin-left: -9.5%;
  }

  #cooperation {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    margin-right: 0;
    padding-bottom: 32px;
  }

  .social-logo {
    margin: auto;
  }
}
</style>
