/**
 * plugins/index.js
 *
 * Automatically included in `./src/main.js`
 */

// Plugins
import router from '@/router';
import { createAuth0 } from '@auth0/auth0-vue';
import vuetify from './vuetify';

export function registerPlugins(app) {
  app
    .use(vuetify)
    .use(router)
    .use(
      createAuth0({
        domain: import.meta.env.VITE_AUTH0_DOMAIN,
        clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
        authorizationParams: {
          audience: import.meta.env.VITE_AUTH0_AUDIENCE,
          redirect_uri: import.meta.env.VITE_AUTH0_CALLBACK_URL
        }
      })
    );
}
