export class FetchService {
  router;
  resourcesUrl;
  sessionService;

  constructor(resourcesUrl, sessionService, router) {
    this.router = router;
    this.resourcesUrl = resourcesUrl;
    this.sessionService = sessionService;
  }

  async fetchWithAuth(apiEndpoint, options, addAuth = true, responseType = 'json') {
    let fetchOptions = options;
    if (addAuth) {
      fetchOptions = await this.getOptionsWithAuth(options);
    }

    let response;
    const url = this.resourcesUrl + apiEndpoint;
    try {
      response = await fetch(url, fetchOptions);
    } catch (error) {
      console.log(`There was an error fetching ${url}: ${error}`);
      throw error;
    }

    if (!response.ok) {
      // ToDo: handle 401, 404, 405 (method not allowed), 5xx
      //   await router.push({ path: '/profile' }).then(() => {
      //     router.go(0);
      //   });
      console.log(`Result of the fetch was not ok ${url}: ${response.status}`);
      return;
    }

    try {
      if (response.status === 204) {
        return;
      }
      if (responseType === 'blob') {
        const blob = await response.blob();
        return blob;
      } else if (responseType === 'text') {
        const text = await response.text();
        return text;
      }
      const responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.log(`There was an error parsing the result of fetching ${url}: ${error}`);
      throw error;
    }
  }

  async getOptionsWithAuth(options) {
    const accessToken = await this.sessionService.asyncGetCurrentToken();
    const authorization = `Bearer ${accessToken}`;
    let optionsHeaders = options.headers;
    if (!optionsHeaders) {
      optionsHeaders = {};
    }
    const newOptions = {
      ...options,
      headers: {
        ...optionsHeaders,
        Authorization: authorization
      }
    };
    return newOptions;
  }
}
