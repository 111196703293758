export class UserAdaptor {
  fetchService;

  constructor(fetchService) {
    this.fetchService = fetchService;
  }

  async asyncDeleteUser(uuid) {
    return this.fetchService.fetchWithAuth('/admin/user/' + uuid, {
      method: 'DELETE'
    });
  }

  async asyncDeleteUserPersonal(uuid) {
    return this.fetchService.fetchWithAuth('/personal/user/' + uuid, {
      method: 'DELETE'
    });
  }

  async asyncGetAllUsers() {
    return this.fetchService.fetchWithAuth('/admin/user/all', {
      method: 'GET'
    });
  }

  async asyncCreateUser(user) {
    return this.fetchService.fetchWithAuth('/user', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(user)
    });
  }

  async asyncChangeUser(user) {
    return this.fetchService.fetchWithAuth('/personal/user/' + user.uuid, {
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(user)
    });
  }

  async asyncGetUser(uuid) {
    return this.fetchService.fetchWithAuth('/user/' + uuid, {
      method: 'GET'
    });
  }

  async asyncUserExists(uuid) {
    return this.fetchService.fetchWithAuth('/user/exists/' + uuid, {
      method: 'GET'
    });
  }

  async asyncGetUserData(uuid) {
    return this.fetchService.fetchWithAuth('/personal/user/' + uuid, {
      method: 'GET'
    });
  }

  async asyncGetUserSector(uuid) {
    return this.fetchService.fetchWithAuth('/sector/personal/user/' + uuid, {
      method: 'GET'
    });
  }
}
