import { reactive } from 'vue';

export class ShowHeaderFooterService {
  constructor() {
    this.serviceState = reactive({ showHeaderFooter: true });
  }

  state() {
    return this.serviceState;
  }

  setShowHeaderFooter(set) {
    this.serviceState.showHeaderFooter = set;
  }
}
