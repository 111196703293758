<script setup>
import LoginButton from '@/components/Buttons/LoginButton.vue';
import LogoutButton from '@/components/Buttons/LogoutButton.vue';
import SignupButton from '@/components/Buttons/SignupButton.vue';
import { inject, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import AdminNavbar from '../Admin/AdminNavbar.vue';

const router = useRouter();

const isAuthenticated = ref(false);
const isAdmin = ref(false);
const isExpandedMobile = ref(false);
const hasCompletedStoredAttempts = ref(false);
const hasCompletedLocalAttempts = ref(false);
const user = ref();

const sessionService = inject('sessionService');
const resultsService = inject('resultsService');
const userService = inject('userService');

onMounted(async () => {
  isAuthenticated.value = sessionService.isUserAuthenticated();
  if (isAuthenticated.value) {
    isAdmin.value = sessionService.isUserAdmin();
    user.value = sessionService.getUser();
  }
  // Add event listener for window resize
  window.addEventListener('resize', handleWindowResize);
  // Call handleWindowResize on mount to set the initial value
  handleWindowResize();
  await getHasCompletedAttempts();
});

function mobileMenuAboutUs() {
  router.push('/about');
  isExpandedMobile.value = false;
}

function mobileMenuQuestionnaire() {
  router.push('/questionnaire');
  isExpandedMobile.value = false;
}

function toggleMobileMenu() {
  isExpandedMobile.value = !this.isExpandedMobile;
}

function gotoAdminPage() {
  router.push('/admin/user');
  isExpandedMobile.value = !this.isExpandedMobile;
}

function goToAdminQuestionnaire() {
  router.push('/admin/questionnaire');
  isExpandedMobile.value = !this.isExpandedMobile;
}

function goToAdminExcelDownload() {
  router.push('/admin/download');
  isExpandedMobile.value = !this.isExpandedMobile;
}

function goHome() {
  router.push('/');
  isExpandedMobile.value = false;
}

function userInfo() {
  router.push('/profile');
  isExpandedMobile.value = false;
}

function handleWindowResize() {
  if (window.innerWidth > 1200) {
    isExpandedMobile.value = false;
  }
}

async function getHasCompletedAttempts() {
  const isAuthenticated = sessionService.isUserAuthenticated();
  let isUserKnown = false;
  let userId;
  if (isAuthenticated) {
    userId = sessionService.getUserId();
    isUserKnown = await userService.asyncUserExists(userId);
  }
  if (isUserKnown) {
    const attempts = await resultsService.getCompletedAttempts(userId);
    hasCompletedStoredAttempts.value = attempts && attempts.length > 0;
  } else {
    let storedResults = JSON.parse(localStorage.getItem('questionnaireResults'));
    if (storedResults) {
      hasCompletedLocalAttempts.value = storedResults['isCompleted'] === true;
    }
  }
}
</script>

<template>
  <header>
    <nav>
      <div class="admin-menu-container">
        <admin-navbar v-if="isAdmin"></admin-navbar>
      </div>

      <div id="logos">
        <div id="mobile-dropdown">
          <button id="mobile-button" @click="toggleMobileMenu">☰</button>
        </div>
        <a id="wij2030-logo"
          ><img src="../../assets/wij2030logo.png" alt="Wij2030" @click="goHome"
        /></a>
      </div>

      <div id="center_content">
        <a href="/about">Over ons</a>
        <a v-if="hasCompletedStoredAttempts" href="/results">Mijn Resultaten</a>
        <a v-else-if="hasCompletedLocalAttempts" href="/results-guest">Mijn Resultaten</a>
        <a v-else href="/questionnaire">Mijn Resultaten</a>
      </div>
      <div v-if="isAuthenticated" id="welcome">
        <span class="welcome-text">Welkom</span>
        <a href="/profile" class="username" v-text="user.first_name + ' ' + user.last_name"></a>
        <LogoutButton />
      </div>
      <div v-if="!isAuthenticated" id="login">
        <SignupButton style="margin-right: 20px" />
        <LoginButton />
      </div>
    </nav>
  </header>

  <div id="dropdown">
    <div v-if="isExpandedMobile" id="mobile-navbar">
      <div v-if="userName">
        <button id="mobile-welcome" v-text="userName" @click="userInfo"></button>
      </div>
      <button @click="mobileMenuAboutUs">Over ons</button>
      <button @click="mobileMenuQuestionnaire">Digitaal kompas</button>
      <button v-if="isAdmin" @click="gotoAdminPage">User Admin Management</button>
      <button v-if="isAdmin" @click="goToAdminQuestionnaire">Admin Questionnaire</button>
      <button v-if="isAdmin" @click="goToAdminExcelDownload">Download Resultaten</button>
      <div v-if="isAuthenticated" id="dropdown-logout">
        <LogoutButton />
      </div>
      <div v-if="!isAuthenticated" id="dropdown-login">
        <SignupButton />
        <LoginButton />
      </div>
    </div>
  </div>
</template>

<style scoped>
.username {
  position: relative;
  display: inline-block;
  color: #565554;
  text-decoration: none;
  font-size: 18px;
}

.username::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #41b06e; /* Color of the underline */
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.username:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.username:hover {
  color: #41b06e; /* Color of the text on hover */
}

.welcome-text {
  color: #565554;
  font-size: 18px;
}

header {
  background-color: white;
  font-weight: bold;
  margin: 0px 30px;
}

header nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 3px solid #565554;
  position: relative;
  overflow: visible;
}

header nav a {
  color: #565554;
  text-decoration: none;
  padding: 15px;
  font-size: 18px;
}

#logos {
  display: flex;
  align-items: center;
}

#wij2030-logo img {
  width: 100px;
  border-radius: 10px;
  cursor: pointer;
}

#hva-logo img {
  width: 160px;
  height: 28px;
}

#center_content {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}

#mobile-dropdown {
  display: none;
}

#mobile-button {
  background-color: #41b06e;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  height: 28px;
  padding: 0 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.admin-menu-container {
  position: absolute;
}

@media (max-width: 1200px) {
  #welcome {
    visibility: hidden;
  }

  header nav a {
    padding: 6px;
  }

  header nav {
    padding-top: 20px;
    padding-left: 15vw;
  }

  #center_content {
    visibility: hidden;
  }

  #register-login {
    visibility: hidden;
  }

  #mobile-dropdown {
    display: block;
  }

  #logos {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0;
  }

  #wij2030-logo img {
    margin-right: 0;
    width: 120px;
    height: 24px;
    cursor: pointer;
  }

  #hva-logo img {
    padding-top: 2px;
    width: 120px;
    height: 24px;
  }

  #mobile-dropdown {
    padding-right: 8px;
  }

  #mobile-navbar {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 20px;
    border-bottom: 3px solid #565554; /* Adjusted the width here */
  }

  #mobile-navbar button {
    display: block;
    width: 100%;
    padding: 5px;
    background-color: transparent;
    border: none;
    text-align: center;
    font-size: 16px;
    color: #565554;
    cursor: pointer;
  }

  .admin-menu-container {
    visibility: hidden;
  }
}

@media (max-width: 1200px) and (min-width: 800px) {
  header nav {
    padding-left: 25vw;
  }

  #login {
    visibility: hidden;
  }
}

@media (max-width: 799px) and (min-width: 450px) {
  header nav {
    padding-left: 22vw;
  }

  #login {
    visibility: hidden;
  }
}

@media (max-width: 449px) and (min-width: 0px) {
  #login {
    visibility: hidden;
  }
}
</style>
