<template>
  <div v-if="isLoading" class="loading">
    <PageLoader />
  </div>
  <div v-else>
    <Header v-if="showHeaderFooterService.state().showHeaderFooter"></Header>
    <v-app>
      <v-main>
        <router-view />
      </v-main>
    </v-app>
    <Footer v-if="showHeaderFooterService.state().showHeaderFooter"></Footer>
  </div>
</template>

<script setup>
import { ExcelDownloadAdaptor } from '@/services/ExcelDownloadAdaptor';
import { FetchService } from '@/services/FetchService';
import { QuestionnaireAdaptor } from '@/services/QuestionnaireAdaptor';
import { ResultAdaptor } from '@/services/ResultAdaptor';
import { SectorAdaptor } from '@/services/SectorAdaptor';
import { SessionService } from '@/services/SessionService';
import { ShowHeaderFooterService } from '@/services/ShowHeaderFooterService';
import { TipAdaptor } from '@/services/TipAdaptor';
import { UserAdaptor } from '@/services/UserAdaptor';
import { WebsiteTextAdaptor } from '@/services/websiteTextAdaptor';
import { provide } from 'vue';
import { useRouter } from 'vue-router';
import Footer from './components/Navigation/footer.vue';
import Header from './components/Navigation/header.vue';
import CONFIG from './config';

const sessionService = new SessionService();
const isLoading = sessionService.authIsLoading();
const showHeaderFooterService = new ShowHeaderFooterService();
const router = useRouter();
const fetchService = new FetchService(CONFIG.BACKEND_URL, sessionService, router);

provide('userService', new UserAdaptor(fetchService));
provide('questionnaireService', new QuestionnaireAdaptor(fetchService));
provide('sessionService', sessionService);
provide('resultsService', new ResultAdaptor(fetchService));
provide('excelDownloadService', new ExcelDownloadAdaptor(fetchService));
provide('websiteTextService', new WebsiteTextAdaptor(fetchService));
provide('tipService', new TipAdaptor(fetchService));
provide('sectorAdaptor', new SectorAdaptor(fetchService));
provide('showHeaderFooterService', showHeaderFooterService);
</script>
