export class TipAdaptor {
  fetchService;

  constructor(fetchService) {
    this.fetchService = fetchService;
  }

  async asyncGetTipTwo() {
    return this.fetchService.fetchWithAuth('/tip/tipTwo', {
      method: 'GET'
    });
  }
  async asyncGetTipThree() {
    return this.fetchService.fetchWithAuth('/tip/tipThree', {
      method: 'GET'
    });
  }

  async asyncUpdateText(data) {
    return this.fetchService.fetchWithAuth('/tip/admin/tipText', {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    });
  }

  async asyncGetAllCategories() {
    return this.fetchService.fetchWithAuth('/admin/category', {
      method: 'GET'
    });
  }

  async asyncGetStrengthAndWeakness() {
    return this.fetchService.fetchWithAuth('/admin/strengthWeakness', {
      method: 'GET'
    });
  }

  async asyncUpdateStrengthOrWeakness(data) {
    return this.fetchService.fetchWithAuth('/admin/strengthWeakness', {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    });
  }

  async asyncUpdateCategory(data) {
    return this.fetchService.fetchWithAuth('/admin/category', {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    });
  }
}
