<template>
  <div v-if="isAdmin" class="admin-navbar">
    <div class="button-container">
      <button @click="toggleMenu" class="dropdown-button">☰</button>
      <div v-if="isExpanded" class="dropdown-content">
        <button @click="gotoAdminPage">User Admin Management</button>
        <button v-if="isExpanded" @click="goToAdminQuestionnaire">Admin Questionnaire</button>
        <button v-if="isExpanded" @click="goToQuestionnaireIntro">Questionnaire Intro</button>
        <button v-if="isExpanded" @click="goToAdminExcelDownload">Download Resultaten</button>
        <button v-if="isExpanded" @click="goToAdminActive">Questionnaire Actief</button>
        <button v-if="isExpanded" @click="goToAdminResults">Alle Resultaten</button>
        <button v-if="isExpanded" @click="goToAdminTips">Admin Tips</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdminNavbar',
  inject: ['sessionService'],
  data() {
    return {
      isAdmin: false,
      isExpanded: false // Initially not expanded
    };
  },
  mounted() {
    // Check if the user is an admin
    this.isAdmin = this.sessionService.isUserAdmin();
  },
  methods: {
    gotoAdminPage() {
      this.$router.push('/admin/user'); // Change '/admin/user' to the actual route for the admin page
      this.toggleMenu();
    },
    goToAdminQuestionnaire() {
      this.$router.push('/admin/questionnaire');
      this.toggleMenu();
    },
    goToQuestionnaireIntro() {
      this.$router.push('/admin/questionnaire-intro');
      this.toggleMenu();
    },
    goToAdminExcelDownload() {
      this.$router.push('/admin/download');
      this.toggleMenu();
    },
    toggleMenu() {
      this.isExpanded = !this.isExpanded; // Toggle the menu visibility
    },
    goToAdminActive() {
      this.$router.push('/admin/active-questionnaire');
      this.toggleMenu();
    },
    goToAdminResults() {
      this.$router.push('/admin/results');
      this.toggleMenu();
    },
    goToAdminTips() {
      this.$router.push('/admin/tips');
      this.toggleMenu();
    }
  }
};
</script>

<style scoped>
.dropdown-button {
  margin-left: -94%;
}

.admin-navbar {
  display: flex;
  align-items: center;
  position: relative; /* Keep the admin navbar within the flow of the header */
}

.button-container {
  position: relative; /* Keep the button container in normal flow */
}

.button-container button {
  cursor: pointer;
  padding: 8px 16px;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
}

.dropdown-content {
  display: flex;
  flex-direction: column;
  background-color: white;
  position: absolute;
  top: 100%; /* Position dropdown below the button */
  left: -65%;
  width: 250px; /* Set width of the dropdown */
  border: 1px solid #ccc;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
</style>
