export class SectorAdaptor {
  fetchService;

  constructor(fetchService) {
    this.fetchService = fetchService;
  }

  async asyncGetAllSectors() {
    return this.fetchService.fetchWithAuth('/sector', { method: 'GET' }).then((sectors) => {
      return sectors.sort((sector1, sector2) => {
        let x = sector1.title.toLowerCase();
        let y = sector2.title.toLowerCase();
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });
    });
  }

  async asyncCreateSector(sector) {
    return this.fetchService.fetchWithAuth('/admin/sector', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(sector)
    });
  }

  async asyncDeleteSector(uuid) {
    return this.fetchService.fetchWithAuth('/admin/sector/' + uuid, {
      method: 'DELETE'
    });
  }

  async asyncUpdateSector(data) {
    return this.fetchService.fetchWithAuth('/admin/sector', {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    });
  }
}
