<template>
  <div class="loading">
    <v-progress-circular indeterminate :size="101"></v-progress-circular>
  </div>
</template>
<style>
.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
