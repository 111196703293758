export class ResultAdaptor {
  fetchService;

  constructor(fetchService) {
    this.fetchService = fetchService;
  }

  async asyncUpdateResult(result) {
    return this.fetchService.fetchWithAuth('/result', {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(result)
    });
  }

  async getSectorNameByUserIdAndAttemptId(uuid, attemptUuid) {
    return this.fetchService.fetchWithAuth(`/result/sector_name/personal/${uuid}/${attemptUuid}`, {
      method: 'GET'
    });
  }

  async countDistinctQuestionnairesByUserUuid(userUuid) {
    return this.fetchService.fetchWithAuth(
      '/result/user/questionnaireAmount/personal/' + userUuid,
      { method: 'GET' }
    );
  }

  async getNonCompletedQuestionnairesByUser(userUuid) {
    const response = await this.fetchService.fetchWithAuth(
      '/result/user/questionnaireCompleted/personal/' + userUuid,
      { method: 'GET' }
    );
    return response;
  }

  async getLatestQuestionnaireFromUserByUserId(uuid) {
    return this.fetchService.fetchWithAuth('/result/personal/latest_questionnaire/' + uuid, {
      method: 'GET'
    });
  }

  async userMadeActiveQuestionnaire(uuid) {
    return this.fetchService.fetchWithAuth('/result/personal/made_active_questionnaire/' + uuid, {
      method: 'GET'
    });
  }

  async getLatestQuestionnaireCountFromUserByUserId(userUuid, questionnaireUuid) {
    return this.fetchService.fetchWithAuth(
      `/result/latest_questionnaire_attempts_count/${userUuid}/${questionnaireUuid}`,
      { method: 'GET' }
    );
  }

  async getAttemptFromLatestQuestionnaireByUserIdAndAttempt(userUuid, questionnaireUuid, attempt) {
    return this.fetchService.fetchWithAuth(
      `/result/attempt/${userUuid}/${questionnaireUuid}/${attempt}`,
      { method: 'GET' }
    );
  }
  async getUserResultsByAttemptId(attemptUuid) {
    return this.fetchService.fetchWithAuth('/result/user_results/' + attemptUuid, {
      method: 'GET'
    });
  }

  async getAllResultsByAttemptUuids(questionnaireUuid) {
    return this.fetchService.fetchWithAuth(
      '/result/all_latest_attempts_questionnaire/' + questionnaireUuid,
      { method: 'GET' }
    );
  }
  async getAllResults() {
    return this.fetchService.fetchWithAuth('/result/admin', {
      method: 'GET'
    });
  }

  async getSectorResultsForLatestAttempts(attemptUuid) {
    return this.fetchService.fetchWithAuth('/result/sector_results/' + attemptUuid, {
      method: 'GET'
    });
  }

  async getShortTermTipByAttempt(attemptUuid) {
    return this.fetchService.fetchWithAuth('/tip/1/' + attemptUuid, {
      method: 'GET'
    });
  }

  async getMiddleLongTermTipByAttempt(attemptUuid, userUuid) {
    return this.fetchService.fetchWithAuth(`/tip/2/personal/${attemptUuid}/${userUuid}`, {
      method: 'GET'
    });
  }

  async getLongTermTipByAttempt(attemptUuid) {
    return this.fetchService.fetchWithAuth('/tip/3/' + attemptUuid, {
      method: 'GET'
    });
  }

  async getCompletedAttempts(userUuid) {
    return this.fetchService.fetchWithAuth('/attempt/personal/completed/' + userUuid, {
      method: 'GET'
    });
  }
}
