export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/tmp/build_92400de4/src/pages/index.vue'),
    /* no children */
  },
  {
    path: '/about',
    name: '/about',
    component: () => import('/tmp/build_92400de4/src/pages/about.vue'),
    /* no children */
  },
  {
    path: '/admin/active-questionnaire',
    name: '/admin.active-questionnaire',
    component: () => import('/tmp/build_92400de4/src/pages/admin.active-questionnaire.vue'),
    /* no children */
  },
  {
    path: '/admin/download',
    name: '/admin.download',
    component: () => import('/tmp/build_92400de4/src/pages/admin.download.vue'),
    /* no children */
  },
  {
    path: '/admin/questionnaire',
    name: '/admin.questionnaire',
    component: () => import('/tmp/build_92400de4/src/pages/admin.questionnaire.vue'),
    /* no children */
  },
  {
    path: '/admin/questionnaire-intro',
    name: '/admin.questionnaire-intro',
    component: () => import('/tmp/build_92400de4/src/pages/admin.questionnaire-intro.vue'),
    /* no children */
  },
  {
    path: '/admin/results',
    name: '/admin.results',
    component: () => import('/tmp/build_92400de4/src/pages/admin.results.vue'),
    /* no children */
  },
  {
    path: '/admin/tips',
    name: '/admin.tips',
    component: () => import('/tmp/build_92400de4/src/pages/admin.tips.vue'),
    /* no children */
  },
  {
    path: '/admin/user',
    name: '/admin.user',
    component: () => import('/tmp/build_92400de4/src/pages/admin.user.vue'),
    /* no children */
  },
  {
    path: '/callback',
    name: '/callback',
    component: () => import('/tmp/build_92400de4/src/pages/callback.vue'),
    /* no children */
  },
  {
    path: '/privacy',
    name: '/privacy',
    component: () => import('/tmp/build_92400de4/src/pages/privacy.vue'),
    /* no children */
  },
  {
    path: '/profile',
    name: '/profile',
    component: () => import('/tmp/build_92400de4/src/pages/profile.vue'),
    /* no children */
  },
  {
    path: '/questionnaire',
    name: '/questionnaire',
    component: () => import('/tmp/build_92400de4/src/pages/questionnaire.vue'),
    /* no children */
  },
  {
    path: '/results',
    name: '/results',
    component: () => import('/tmp/build_92400de4/src/pages/results.vue'),
    /* no children */
  },
  {
    path: '/results-guest',
    name: '/results-guest',
    component: () => import('/tmp/build_92400de4/src/pages/results-guest.vue'),
    /* no children */
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

